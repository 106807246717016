<style>
.v-application .white--text {
  color: #000 !important;
  caret-color: #000 !important;
  font-weight: bolder;
  font-size: 5px;
}
@media (max-width: 1281px) {
  .v-main__wrap > .v-application .white--text {
    font-size: 5px !important;
    line-height: 5px !important;
    padding: 4px 0px;
  }
}
@media (min-width: 1281px) {
  .v-application .white--text {
    font-size: 12px !important;
  }
}
.v-card__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  background: #fff;
}

.students {
  display: flex;
  list-style: none;
  padding: 0;
}
.student ~ .student::before {
  content: ", ";
}
.theme--dark.v-sheet {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: black;
}
.v-calendar .v-event {
  white-space: unset !important;
  overflow-wrap: break-word;
}
@media (max-width: 1281px) {
  .v-calendar .v-event {
    line-height: 10px !important;
    font-size: 5px !important;
  }
}
@media (max-width: 1281px) {
  .v-event.v-event-start.v-event-end.white--text {
    height: auto !important;
    font-size: 5px !important;
  }
}
@media (max-width: 1281px) {
  .v-event.v-event-end.white--text {
    height: auto !important;
    font-size: 5px !important;
  }
}

@media (min-width: 1281px) {
  .v-event.v-event-start.v-event-end.white--text {
    height: auto !important;
    font-size: 12px !important;
  }
}
@media (max-width: 1281px) {
  .v-calendar-monthly.v-calendar-weekly.v-calendar.theme--light.v-calendar-events {
    width: 168% !important;
  }
}
@media (min-width: 960px) {
  .v-toolbar__title {
    font-size: 1.25rem;
    margin: 25px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }
}
@media (max-width: 1281px) {
  .v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-right: 20px;
  }
}
.eventDialog {
  overflow-y: hidden !important;
}
.v-dialog .v-toolbar__title {
  white-space: normal;
}
@media (min-width: 1281px) {
  .v-dialog {
    max-width: 50% !important;
  }
}
@media (max-width: 1281px) {
  .v-dialog {
    max-width: 90% !important;
  }
}
@media (max-width: 1281px) {
  .v-application .px-2 {
    padding-left: 0px !important;
  }
}
.column-container {
  column-count: 3; /* Set the number of columns */
  column-gap: 10px; /* Adjust the gap between columns as needed */
  max-width: 600px; /* Adjust the max width of the container as needed */
}
.v-calendar-weekly__day.v-past {
  height: auto !important;
}
.td-container {
  display: flex;
  justify-content: space-between;
  margin: 10px; /* Adjust the margin as needed */
}
.v-dialog .v-toolbar__content {
  height: auto !important;
}

.v-dialog .v-toolbar {
  height: auto !important;
}
</style>
<template>
  <v-app>
    <!-- For Desktop View -->
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-sheet
        tile
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        style="text-align: center"
      >
        <!-- Dropdown for Month, Day, Week, List -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#38227A"
              dark
              class="px-2"
              style="border-radius: 10px; margin: 10px 0px; margin-left: 13px"
              v-on="on"
            >
              <span style="margin-left: 5px; text-transform: capitalize">{{
                type
              }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="newListCalender">
              <v-list-item-title>List</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-left</v-icon
          >
          <b>{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-5 col-sm-12 col-xs-12"
        style="padding: 1px; padding-top: 1%; text-align: center"
      >
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px; margin: 10px 5px; margin-left: 13px"
          @click="toggleAddFilterModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Filter</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px; margin: 10px 5px"
          @click="toggleAddEventModal({ show: true, type: 'add' })"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Event Support Staff' ||
            this.adminAccess === 'Sales Representative'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Event +</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px; margin: 10px 5px"
          @click="toggleAddTaskModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Task +</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px"
          @click="togglePlayerAvailabilityModal({ show: true, type: 'add' })"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Sales Representative'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Available Player</span
          >
        </v-btn>
        &nbsp;
        <!-- <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px"
          @click="
            togglePlayerAvailabilityEventModal({ show: true, type: 'add' })
          "
          v-if="this.adminAccess === 'Event Support Staff'|| this.adminAccess === 'Super Admin' "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Book Event Slot</span
          >
        </v-btn>
      &nbsp;  -->
        <!--   @click="
            togglePlayerAvailabilityEventEditModal({ show: true, type: 'add' })
          "--><v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px"
          @click="playerListView()"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Event Support Staff'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >PlayerList</span
          >
        </v-btn>
      </v-sheet>
    </v-row>

    <!-- For Mobile View -->
    <v-row v-if="$vuetify.breakpoint.xsOnly">
      <v-sheet tile class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
        <!-- Dropdown for Month, Day, Week, List -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#38227A"
              dark
              class="px-2"
              style="
                border-radius: 10px;
                margin: 10px 0px;
                margin-left: 13px;
                font-size: 7px;
                height: 18px;
                min-width: 50px;
              "
              v-on="on"
            >
              <span style="margin-left: 5px; text-transform: capitalize">{{
                type
              }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="newListCalender">
              <v-list-item-title>List</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            margin: 10px 5px;
            margin-left: 13px;
            font-size: 7px;
            height: 18px;
            min-width: 50px;
          "
          @click="toggleAddFilterModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Filter</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            margin: 10px 5px;
            font-size: 7px;
            height: 18px;
            min-width: 50px;
          "
          @click="toggleAddEventModal({ show: true, type: 'add' })"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Event Support Staff' ||
            this.adminAccess === 'Sales Representative'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Event +</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            margin: 10px 5px;
            font-size: 7px;
            height: 18px;
            min-width: 50px;
          "
          @click="toggleAddTaskModal({ show: true, type: 'add' })"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Task +</span
          >
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            font-size: 7px;
            height: 18px;
            min-width: 50px;
          "
          @click="togglePlayerAvailabilityModal({ show: true, type: 'add' })"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Event Support Staff' ||
            this.adminAccess === 'Sales Representative'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Available Player</span
          >
        </v-btn>
        <!--  <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px;font-size: 7px;
    margin-left: 13px;height: 18px"
          @click="
            togglePlayerAvailabilityEventModal({ show: true, type: 'add' })
          "
          v-if="this.adminAccess === 'Event Support Staff'|| this.adminAccess === 'Super Admin' "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Book Event Slot</span
          >
        </v-btn>
      &nbsp;  -->
        <!--  @click="
            togglePlayerAvailabilityEventEditModal({ show: true, type: 'add' })
          "
          --><v-btn
          color="#38227A"
          dark
          class="px-2"
          style="
            border-radius: 10px;
            font-size: 7px;
            margin-left: 13px;
            height: 18px;
          "
          @click="playerListView()"
          v-if="
            this.adminAccess === 'Super Admin' ||
            this.adminAccess === 'Event Support Staff'
          "
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >PlayerList</span
          >
        </v-btn>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-left</v-icon
          >
          <b style="font-size: 14px">{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
    </v-row>
    <v-row>
      <v-col cols="6" md="4">
        <v-text-field
          hide-details=""
          outlined
          :disabled="loading"
          height="43px"
          dense
          v-model="search"
          label="Search by City, State, Team, Player"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          style="margin-left: 15px"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6">
        <v-btn
          color="#38227A"
          dark
          class="px-2"
          style="border-radius: 10px; margin: 0px 5px; height: 42px"
          @click="getMonthCalendarData()"
        >
          <span style="margin-left: 5px; text-transform: capitalize"
            >Search</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="ma-4"
      style="height: 140%; overflow-x: auto; overflow-y: hidden"
    >
      <v-dialog
        class="eventDialog"
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        persistent
      >
        <v-card min-width="auto" style="background: #ffffff">
          <v-toolbar
            dark
            color="#ffffff"
            v-if="this.selectedEvent.eventids == 1"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
              <v-icon
                color="black"
                @click="closeEventPopup()"
                style="
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  background-color: #0003;
                  border-radius: 50%;
                  padding: 2px;
                "
                >mdi-close</v-icon
              >
              <!-- <v-icon
                text
                size="27"
                color="black"
                style="cursor: pointer; position: relative"
                @click="getMonthCalendarData()"
              >mdi-delete
              </v-icon>-->
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="#edc967a1"
            dark
            v-if="this.selectedEvent.color == '#edc967a1'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="position: absolute; right: 5px; top: 5px background-color: #0003;
    border-radius: 50%;
    padding: 2px;"
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-if="this.selectedEvent.eventids == 4"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-if="this.selectedEvent.eventids == 3"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-else-if="this.selectedEvent.eventids == 2"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#F9B4F6"
            dark
            v-else-if="this.selectedEvent.color == '#F9B4F6'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#a3c585"
            dark
            v-else-if="this.selectedEvent.color == '#a3c585'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-toolbar
            color="#a3c585"
            dark
            v-else-if="this.selectedEvent.color == '#38227A'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-else-if="this.selectedEvent.eventids == 8"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
            <v-icon
              color="black"
              @click="closeEventPopup()"
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #0003;
                border-radius: 50%;
                padding: 2px;
              "
              >mdi-close</v-icon
            >
          </v-toolbar>
          <game-modal></game-modal>
          <assembly-schedual-modal></assembly-schedual-modal>
          <v-card-text style="max-height: 600px; overflow-y: auto">
            <div
              class="text-center"
              v-if="this.selectedEvent.eventids == 1"
              style="cursor: pointer"
            >
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
              <div
                style="
                  text-align: justify;
                  font-size: 16px;
                  text-decoration: underline;
                "
              >
                <strong v-if="this.adminAccess == 'Player'"
                  >{{ this.gameData.game_date_str }},
                  {{ this.gameData.game_time_str }}-{{
                    converTime(this.gameData.end_time)
                  }}
                  | {{ this.gameData.game_timezone }}</strong
                >
                <strong @click="visitGamePanelHandler(selectedEvent.id)" v-else
                  >{{ this.gameData.game_date_str }},
                  {{ this.gameData.game_time_str }}-{{
                    converTime(this.gameData.end_time)
                  }}
                  | {{ this.gameData.game_timezone }}</strong
                >
                &nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEditViewGameModal({
                      show: true,
                      type: 'edit',
                      dashboard: true,
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>

                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <br />
              <div v-if="this.gameData.player_confirmations.length != 0">
                <strong style="margin-right: 92%; font-size: 17px"
                  >Players:
                </strong>
                <div
                  v-for="(monthlySlot, index) in playerGameItems"
                  :key="index"
                >
                  <tr class="students">
                    <td>{{ monthlySlot.player_name }}</td>
                    &nbsp;&nbsp;
                    <td
                      style="color: green; font-weight: 600"
                      v-if="monthlySlot.confirmation_status == 'confirmed'"
                    >
                      Confirmed
                    </td>
                    <td style="color: red; font-weight: 600" v-else>
                      Not Confirmed
                    </td>
                  </tr>
                </div>
              </div>

              <br />
              <div style="text-align: justify; font-size: 16px">
                <strong>Game City and State: </strong
                >{{ this.gameData.city }},{{ this.gameData.state_name }}
                <br />
                <br />
                <strong style="color: red">Ticket Sold Info: </strong
                >{{ this.gameData.total_online_ticket_sold }} /
                {{ this.gameData.total_seat }}
                <br />
                <br />
                <!-- <strong style="color: red">Presale Ticket: </strong
                >{{ this.gameData.total_ticket_sold }} as of
                {{ this.gameData.presale_date_str }}
                <br />
                <br />-->
                <strong style="font-size: 20px; text-decoration: underline"
                  >Previous Event Info:</strong
                >
                <br />
                <strong>Year: </strong>2023
                <br />
                <strong>Ticket Sale: </strong
                >{{ this.gameData.total_ticket_sold }}
                <br />
                <strong>Team: </strong>{{ this.gameData.team_name }}
                <br />
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:
                </strong>
                <br />
                <strong>Host Name: </strong
                >{{ this.gameData.host_name }} &nbsp;&nbsp;&nbsp;
                <br />
                <strong>Cell Phone: </strong>{{ this.gameData.host_phone }}
                <br />
                <strong>Email: </strong>{{ this.gameData.host_email }}
                <br />
                <strong>Sales Rep: </strong>{{ this.gameData.sales_rep_name }}
                <br />
                <strong>Event Support Wizard: </strong
                >{{ this.gameData.event_support_staff }}
                <br />
                <br />
                <br />
                <div v-if="this.gameData.assemble_data_sameday != null">
                  <strong style="font-size: 16px; text-decoration: underline">
                    FREE ASSEMBLY SCHEDULE:
                  </strong>
                  <br />

                  <strong>Date: </strong
                  >{{ this.gameData.assemble_data_sameday[0].assemble_date_2 }}
                  <br />
                  <strong>Time: </strong
                  >{{
                    this.gameData.assemble_data_sameday[0].start_time_str
                  }}-{{ this.gameData.assemble_data_sameday[0].end_time_str }}
                  <br />
                </div>
                <br />
                <br />
                <br />

                <strong style="font-size: 20px; text-decoration: underline"
                  >General Details:
                </strong>
                <br />
                <strong>Host Group Name: </strong>Host Group Name
                <br />
                <strong>Game Date & Time: </strong
                >{{ this.gameData.game_date_str }}
                {{ converTime(this.gameData.start_time) }}-{{
                  converTime(this.gameData.end_time)
                }}
                <br />
                <strong>Arrival Time: </strong
                >{{ this.gameData.arrival_time_str }}
                <br />
                <strong>Site: </strong>{{ this.gameData.event_location_name }}
                <br />
                <strong> Event Address: </strong
                >{{ this.gameData.event_location }}, {{ this.gameData.city }},
                {{ this.gameData.state_name }}
                <br />
                <strong>Seating Capacity: </strong>-
                <br />
                <strong>Contract Type: </strong
                >{{ this.gameData.contract_type }}

                <br />
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Notes:
                </strong>
                <br />
                <br />
                <div v-html="this.gameData.notes"></div>
                <!-- <ul>
                  <li v-for="(note, index) in gameNotes" :key="index">
                    <div v-html="note"> </div>
                  </li>
                </ul>-->
                <br />
                <br />
                <br />
                <div
                  v-if="
                    this.gameData.advance_ticket_data != null &&
                    this.gameData.door_ticket_data != null
                  "
                >
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Ticket Prices:</strong
                  >

                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="td-container">
                        <div>
                          <label>Advance Ticket Name</label>
                          <div
                            v-for="(items, i) in this.gameData
                              .advance_ticket_data"
                            :key="i"
                          >
                            <p v-text="items.item_name"></p>
                          </div>
                        </div>
                        <div>
                          <label>ADV</label>
                          <div
                            v-for="(items, p) in this.gameData
                              .advance_ticket_data"
                            :key="p"
                          >
                            <p v-text="items.default_price"></p>
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="td-container">
                        <div>
                          <label>Door Ticket Name</label>
                          <div
                            v-for="(items, i) in this.gameData.door_ticket_data"
                            :key="i"
                          >
                            <p v-text="items.item_name"></p>
                          </div>
                        </div>
                        <div>
                          <label>Door</label>
                          <div
                            v-for="(items, p) in this.gameData.door_ticket_data"
                            :key="p"
                          >
                            <p v-text="items.default_price"></p>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <br />
                <br />
                <div v-if="this.gameData.hotel_data != null">
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Hotel-
                  </strong>
                  <br />
                  <strong>Name: </strong>{{ this.gamehotalData.name }}
                  <br />
                  <strong>Address: </strong
                  >{{ this.gamehotalData.hotel_address }}
                  <br />
                  <strong>Email: </strong>{{ this.gamehotalData.contact_email }}
                  <br />
                  <strong>Confirmation: </strong
                  >{{ this.gamehotalData.confirmation_number }}
                  <br />
                  <strong>Reservation Under: </strong
                  >{{ this.gamehotalData.contact_person_name }}
                </div>

                <br />
                <br />
              </div>
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </div>

            <div class="text-center" v-if="this.selectedEvent.eventids == 3">
              <hr
                style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;#41a7f5ad'
                  "
              />

              <div style="text-align: justify; font-size: 16px">
                <strong
                  >{{ this.assemblyData.assemble_date_str }},
                  {{ this.assemblyData.start_time_str }}-{{
                    this.assemblyData.end_time_str
                  }}
                  | {{ this.assemblyData.assemble_timezone }}</strong
                >&nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAssemblyModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                &nbsp;
                <v-icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="visitAllAssembly(assemblyData.game)"
                >
                  mdi-eye
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />

                <div v-if="this.assemblyData.player_confirmations.length != 0">
                  <strong>Players: </strong>
                  <div
                    v-for="(monthlySlot, index) in playeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <div v-if="this.assemblyData.player_confirmations.length == 0">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in playeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <strong style="font-size: 20px; text-decoration: underline"
                  >Info:
                </strong>
                <br />
                <strong>Meeting Place: </strong>{{ this.assemblyData.school }}
                <br />
                <strong>Meeting Time: </strong
                >{{ this.assemblyData.meeting_time_str }}
                <br />
                <strong>Address: </strong>{{ this.assemblyData.address }}
                <br />
                <br />
                <strong>No.Of Assemblies: </strong
                >{{ this.assemblyData.total_assemblies }}
                <br />
                <strong>No. Of Student: </strong
                >{{ this.assemblyData.total_student }}

                <br />
                <br />
                <strong>Driver Name: </strong
                >{{ this.assemblyData.driver_name }}
                <br />
                <strong>Driver Cell: </strong>{{ this.assemblyData.phone }}

                <br />
                <br />
                <div>
                  <v-col cols="8">
                    <strong style="margin-left: -11px">Description: </strong>
                    <div
                      v-html="formattedSchoolGroups"
                      style="line-height: 1"
                    ></div>
                  </v-col>
                </div>
                <strong>Contact: </strong>{{ this.assemblyData.host_name }}
                <br />
                <strong>Phone No.: </strong>{{ this.assemblyData.host_phone }}
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Game Info:
                </strong>
                <br />
                <br />
                <strong style="color: red">Ticket Sold Info: </strong
                >{{ this.assemblyData.total_ticket_sold }} /
                {{ this.assemblyData.all_seats }}
                <br />
                <strong>Game City and State: </strong
                >{{ this.assemblyData.assemble_city }},{{
                  this.assemblyData.assemble_state
                }}
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Previous Event Info:</strong
                >
                <br />
                <strong>Year: </strong>2023
                <br />
                <strong>Ticket Sale: </strong>
                <br />
                <strong>Team: </strong
                >{{ this.assemblyData.assemble_team_name }}

                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >General Details:
                </strong>
                <br />
                <strong>Team Doing Game: </strong>
                {{ this.assemblyData.assemble_team_name }}
                <br />
                <strong>Host Group Name: </strong
                >{{ this.assemblyData.organization_name }}
                <br />
                <strong>Game Date And Time: </strong
                >{{ this.assemblyData.game_date }} -
                {{ converTime(this.assemblyData.game_starttime) }},
                <br />
                <strong>Site: </strong
                >{{ this.assemblyData.event_location_name }}
                <br />
                <strong> Event Address: </strong
                >{{ this.assemblyData.event_location }}
                <br />
                <strong>Seating Capacity: </strong>
                <br />
                <strong>Contract Type: </strong
                >{{ this.assemblyData.contract_type }}
                <br />
                <strong> Repeat game (Y/N?): </strong>
                <br />
                <strong> Wiz Kid? Yes </strong>
                <br />
                <br />
                <!-- <strong>Note: </strong>
                <ul>
                  <li v-for="(note, index) in assemblegameNotes" :key="index">
                  <div v-html="note"> </div>
                  </li>
                </ul>-->
                   <div
                  v-if="
                    this.assemblyData.advance_ticket_data != null &&
                    this.assemblyData.door_ticket_data != null
                  "
                >
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Ticket Prices:</strong
                  >

                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="td-container">
                        <div>
                          <label>Advance Ticket Name</label>
                          <div
                            v-for="(items, i) in this.assemblyData
                              .advance_ticket_data"
                            :key="i"
                          >
                            <p v-text="items.item_name"></p>
                          </div>
                        </div>
                        <div>
                          <label>ADV</label>
                          <div
                            v-for="(items, p) in this.assemblyData
                              .advance_ticket_data"
                            :key="p"
                          >
                            <p v-text="items.default_price"></p>
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="td-container">
                        <div>
                          <label>Door Ticket Name</label>
                          <div
                            v-for="(items, i) in this.assemblyData.door_ticket_data"
                            :key="i"
                          >
                            <p v-text="items.item_name"></p>
                          </div>
                        </div>
                        <div>
                          <label>Door</label>
                          <div
                            v-for="(items, p) in this.assemblyData.door_ticket_data"
                            :key="p"
                          >
                            <p v-text="items.default_price"></p>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:
                </strong>
                <br />
                <strong>Host Name: </strong
                >{{ this.assemblyData.host_name }} &nbsp;&nbsp;&nbsp;
                <br />
                <strong>Cell Phone: </strong>{{ this.assemblyData.host_phone }}
                <br />
                <strong>Email: </strong>{{ this.assemblyData.host_email }}
                <br />
                <strong>Sales Rep: </strong
                >{{ this.assemblyData.sales_rep_name }}
                <br />
                <strong>Event Support Wizard: </strong
                >{{ this.assemblyData.event_support_staff }}
                <br />
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <div class="text-center" v-if="this.selectedEvent.eventids == 4">
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.hotelData.checking_in_date_str }} |
                {{ this.hotelData.hotel_timezone }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleHotelInfoModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  size="18"
                  color="red"
                  @click="
                    toggleDeleteHotelModal({
                      Delete: true,
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                  >mdi-delete</v-icon
                >
                <br />
                <br />
                <strong>Check in date: </strong
                >{{ this.hotelData.checking_in_date_str }} &nbsp;
                <strong>Check out date: </strong
                >{{ this.hotelData.checking_out_date_str }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <div v-if="this.hotelData.player_confirmations.length != 0">
                  <strong>Players: </strong>
                  <div
                    v-for="(monthlySlot, index) in hotelplayeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <div v-if="this.hotelData.player_confirmations.length == 0">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in hotelplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <br />
                <strong>Category: </strong>Hotel
                <br />
                <br />
                <strong>Description: </strong> {{ this.hotelData.note }}
                <br />
                <br />
                <strong>Hotel Name: </strong> {{ this.hotelData.name }}
                <br />
                <strong>Hotel Address: </strong>
                {{ this.hotelData.hotel_address }}
                <br />
                <strong>Contact Person Name: </strong>
                {{ this.hotelData.contact_person_name }}
                <br />
                <strong>Contact Person Email: </strong>
                {{ this.hotelData.contact_email }}
                <br />
                <strong>Confirmation Number: </strong>
                {{ this.hotelData.confirmation_number }}
                <br />
                <strong>Early Check In:</strong
                ><span v-if="this.hotelData.early_check_in == true">Yes</span>
                <span v-else>No</span>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>

            <div
              class="text-center"
              v-if="this.selectedEvent.color == '#a3c585'"
            >
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.ptoDetail.start_date_str }} -
                {{ this.ptoDetail.end_date_str }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEventModal({
                      show: true,
                      type: 'views',
                      id: selectedEvent.id,
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <strong>Category: </strong>PTO
                <br />
                <br />
                <strong>Description: </strong>
                <div v-html="this.ptoDetail.note"></div>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <div class="text-center" v-if="this.selectedEvent.eventids == 8">
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.customDetail.date_str }}
                {{ converTime(this.customDetail.start_time) }}-{{
                  converTime(this.customDetail.end_time)
                }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEventModal({
                      show: true,
                      type: 'view',
                      id: selectedEvent.id,
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleDeleteCustomEventModal({
                      Delete: true,
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-delete
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />
                <div v-if="this.customDetail.player_confirmations.length != 0">
                  <strong>Players: </strong>
                  <div
                    v-for="(monthlySlot, index) in customplayeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <br />
                <div v-if="this.customDetail.player_confirmations.length == 0">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in customplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <strong>Category: </strong>Custom Event
                <br />
                <br />
                <strong>Meeting Place: </strong>
                {{ this.customDetail.school }}
                <br />
                <strong>Meeting Time: </strong>
                {{ this.customDetail.meeting_time_str }}
                <br />
                <strong>Meeting Address: </strong>
                {{ this.customDetail.address }}
                <br />
                <strong>Description: </strong>
                <div v-html="this.customDetail.note"></div>
                <br />
                <strong>Contact Name: </strong
                >{{ this.customDetail.contact_name }}
                <br />
                <strong>Contact Number: </strong>{{ this.customDetail.phone }}
                <br />
                <strong>Sales Rep: </strong>
                {{ this.customDetail.sales_rep_name }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <div
              class="text-center"
              v-if="this.selectedEvent.color == '#F9B4F6'"
            >
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.ptoDetail.start_date_str }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddTaskModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  size="18"
                  color="red"
                  @click="
                    toggleDeletePTOModal({
                      Delete: true,
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                  >mdi-delete</v-icon
                >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />
                <strong>Category: </strong>Task
                <br />
                <br />
                <strong>Description: </strong>
                <div v-html="this.ptoDetail.note"></div>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>

            <div class="text-center" v-if="this.selectedEvent.eventids == 2">
              <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('KO')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.campaignData.kick_off_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div
                  v-if="
                    this.campaignData.kickoff_player_confirmations.length != 0
                  "
                >
                  <strong style="margin-right: 92%; font-size: 17px"
                    >Players:
                  </strong>
                  <div
                    v-for="(monthlySlot, index) in kickoffplayeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <div
                  v-if="
                    this.campaignData.kickoff_player_confirmations.length == 0
                  "
                >
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in kickoffplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <br />

                <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong>
                {{ this.campaignData.organization_name }} <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Address:</strong>
                  {{ this.campaignData.school_address }} <br />
                  <br />
                </div>
                <strong>Time:</strong>
                {{ this.campaignData.kick_off_start_time_str }} -
                {{ this.campaignData.kick_off_end_time_str }} <br />
                <br />
                <strong>Additional Kick Off:</strong>
                <div v-html="this.campaignData.additional_kickoff"></div>
                <br />
                <strong>Student Enrollment:</strong
                >{{ this.campaignData.total_student }}/{{
                  this.campaignData.school_info_total_student
                }}
                <br />
                <br />
                <strong>Note: </strong>
                <div v-html="this.campaignData.notes"></div>
                <br />
                <br />

                <div v-if="this.campaignData.campaign_type == 'district'">
                  <h4>School List</h4>
                  <ul>
                    <li v-for="school in schoolListData" :key="school.id">
                      <h4>{{ school.school_name }}</h4>
                      <p>School Address:{{ school.school_address }}</p>
                      <p>
                        Pre-registration date:
                        {{ school.pre_registration_date_str }} ,{{
                          school.pre_registration_start_time_str
                        }}-{{ school.pre_registration_end_time_str }}
                      </p>
                      <p>
                        Kick-off date: {{ school.kickoff_date_str }} ,{{
                          school.kickoff_starttime_str
                        }}-{{ school.kickoff_endtime_str }}
                      </p>
                      <p>
                        Special-giveway date:
                        {{ school.kickoff_date_golden_ball_date_strstr }} ,{{
                          school.golden_ball_start_time_str
                        }}-{{ school.golden_ball_end_time_str }}
                      </p>
                      <p>
                        Graduation date: {{ school.graduation_date_str }} ,{{
                          school.graduation_starttime_str
                        }}-{{ school.graduation_endtime_str }}
                      </p>
                      <p>Low Goal: {{ school.low_goal }}</p>
                      <p>Middle Goal: {{ school.middle_goal }}</p>
                      <p>Top Goal: {{ school.top_goal }}</p>
                      <p>Low Goal Purpose: {{ school.low_goal_purpose }}</p>
                      <p>
                        Middle Goal Purpose: {{ school.middle_goal_purpose }}
                      </p>
                      <p>Top Goal Purpose: {{ school.top_goal_purpose }}</p>
                      <p>Other Incentive:</p>
                      <div v-html="school.other_incentive"></div>
                      <br />
                      <p>Notes:</p>
                      <div v-html="school.notes"></div>
                      <p>
                        Money Raised: {{ school.orignal_total_fund_raised_50 }}
                      </p>
                      <p>Financial Goal: {{ school.school_goal_50 }}</p>
                    </li>
                  </ul>
                </div>

                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Money Raised:</strong>
                  {{ this.campaignData.original_total_fund_raised_50 }}
                  <br />
                  <strong>Financial Goal:</strong>
                  {{ this.campaignData.total_goal_50 }}
                </div>
                <br />
                <br />
                <strong>Description: </strong>
                <div v-html="this.campaignData.special_message"></div>
                <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>School Goals And Incentives:</strong><br />
                  Low Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.low_goal
                  }}
                  <br />
                  Low Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.low_goal_purpose
                  }}
                  <br />
                  Middle Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal
                  }}
                  <br />
                  Middle Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal_purpose
                  }}
                  <br />
                  Top Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.top_goal
                  }}
                  <br />
                  Top Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.top_goal_purpose
                  }}
                  <br />
                  <br />
                  <strong>Other Incentives:</strong>
                  <div v-html="this.campaignData.other_incentive"></div>

                  <strong>School Notes: </strong>
                  <div v-html="this.campaignData.school_notes"></div>
                </div>
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                <br />
                <strong>Name:</strong>{{ this.campaignData.host_name }}
                <br />
                <strong>Email:</strong> {{ this.campaignData.host_email }}
                <br />
                <strong>Phone No.:</strong>{{ this.campaignData.host_phone }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('GB')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                {{ this.campaignData.gold_ball_giveaway_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div
                  v-if="
                    this.campaignData.goldball_player_confirmations.length != 0
                  "
                >
                  <strong style="margin-right: 92%; font-size: 17px"
                    >Players:
                  </strong>
                  <div
                    v-for="(monthlySlot, index) in goldballplayeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <div
                  v-if="
                    this.campaignData.goldball_player_confirmations.length == 0
                  "
                >
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in goldballplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <br />
                <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong>
                {{ this.campaignData.organization_name }} <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Address:</strong>
                  {{ this.campaignData.school_address }} <br />
                  <br />
                </div>
                <strong>Time:</strong>
                {{ this.campaignData.gold_ball_start_time_str }} -
                {{ this.campaignData.gold_ball_end_time_str }} <br />
                <br />
                <strong>Student Enrollment:</strong>
                {{ this.campaignData.total_student }}/{{
                  this.campaignData.school_info_total_student
                }}
                <br />
                <br />
                <strong>Note: </strong><br />
                <div v-html="this.campaignData.notes"></div>
                <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'district'">
                  <h4>School List</h4>
                  <ul>
                    <li v-for="school in schoolListData" :key="school.id">
                      <h4>{{ school.school_name }}</h4>
                      <p>School Address:{{ school.school_address }}</p>
                      <p>
                        Pre-registration date:
                        {{ school.pre_registration_date_str }} ,{{
                          school.pre_registration_start_time_str
                        }}-{{ school.pre_registration_end_time_str }}
                      </p>
                      <p>
                        Kick-off date: {{ school.kickoff_date_str }} ,{{
                          school.kickoff_starttime_str
                        }}-{{ school.kickoff_endtime_str }}
                      </p>
                      <p>
                        Special-giveway date:
                        {{ school.kickoff_date_golden_ball_date_strstr }} ,{{
                          school.golden_ball_start_time_str
                        }}-{{ school.golden_ball_end_time_str }}
                      </p>
                      <p>
                        Graduation date: {{ school.graduation_date_str }} ,{{
                          school.graduation_starttime_str
                        }}-{{ school.graduation_endtime_str }}
                      </p>
                      <p>Low Goal: {{ school.low_goal }}</p>
                      <p>Middle Goal: {{ school.middle_goal }}</p>
                      <p>Top Goal: {{ school.top_goal }}</p>
                      <p>Low Goal Purpose: {{ school.low_goal_purpose }}</p>
                      <p>
                        Middle Goal Purpose: {{ school.middle_goal_purpose }}
                      </p>
                      <p>Top Goal Purpose: {{ school.top_goal_purpose }}</p>
                      <p>Other Incentive:</p>
                      <span v-html="school.other_incentive"></span>
                      <br />
                      <p>Notes:</p>
                      <div v-html="school.notes"></div>
                      <p>
                        Money Raised: {{ school.orignal_total_fund_raised_50 }}
                      </p>
                      <p>Financial Goal: {{ school.school_goal_50 }}</p>
                    </li>
                  </ul>
                </div>

                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Money Raised:</strong>
                  {{ this.campaignData.original_total_fund_raised_50 }}
                  <br />
                  <strong>Financial Goal:</strong>
                  {{ this.campaignData.total_goal_50 }}
                </div>
                <br />
                <br />
                <strong>Description: </strong>
                <div v-html="this.campaignData.special_message"></div>
                <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>School Goals And Incentives:</strong><br />
                  Low Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.low_goal
                  }}
                  <br />
                  Low Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.low_goal_purpose
                  }}
                  <br />
                  Middle Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal
                  }}
                  <br />
                  Middle Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal_purpose
                  }}
                  <br />
                  Top Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.top_goal
                  }}
                  <br />
                  Top Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.top_goal_purpose
                  }}
                  <br />
                  <br />
                  <strong>Other Incentives:</strong>
                  <div v-html="this.campaignData.other_incentive"></div>
                  <strong>School Notes: </strong>
                  <div v-html="this.campaignData.school_notes"></div>
                </div>
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                <br />
                <strong>Name:</strong>{{ this.campaignData.host_name }}
                <br />
                <strong>Email:</strong> {{ this.campaignData.host_email }}
                <br />
                <strong>Phone No.:</strong>{{ this.campaignData.host_phone }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <div
                style="text-align: justify; font-size: 16px"
                v-if="this.chekinclude.includes('GR')"
              >
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.campaignData.graduation_celebration_date_str_3 }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="editCampaign()"
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div
                  v-if="this.campaignData.grad_player_confirmations.length != 0"
                >
                  <strong style="margin-right: 92%; font-size: 17px"
                    >Players:
                  </strong>
                  <div
                    v-for="(monthlySlot, index) in graduationplayeritems1"
                    :key="index"
                  >
                    <tr class="students">
                      <td>{{ monthlySlot.player_name }}</td>
                      &nbsp;&nbsp;
                      <td
                        style="color: green; font-weight: 600"
                        v-if="monthlySlot.confirmation_status == 'confirmed'"
                      >
                        Confirmed
                      </td>
                      <td style="color: red; font-weight: 600" v-else>
                        Not Confirmed
                      </td>
                    </tr>
                  </div>
                </div>
                <div
                  v-if="this.campaignData.grad_player_confirmations.length == 0"
                >
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in graduationplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <br />
                <strong>Category:</strong> Wizfit
                <br />
                <br />
                <strong>Location:</strong>
                {{ this.campaignData.organization_name }} <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Address:</strong>
                  {{ this.campaignData.school_address }}<br />
                  <br />
                </div>
                <strong>Time:</strong>
                {{ this.campaignData.graduation_start_time_str }} -
                {{ this.campaignData.graduation_end_time_str }} <br />
                <br />
                <strong>Additional Graduation:</strong>
                <div v-html="this.campaignData.additional_graduation"></div>
                <br />
                <strong>Student Enrollment:</strong
                >{{ this.campaignData.total_student }}/{{
                  this.campaignData.school_info_total_student
                }}
                <br />
                <br />
                <strong>Note: </strong><br />
                <div v-html="this.campaignData.notes"></div>
                <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'district'">
                  <h4>School List</h4>
                  <ul>
                    <li v-for="school in schoolListData" :key="school.id">
                      <h4>{{ school.school_name }}</h4>
                      <p>School Address: {{ school.school_address }}</p>
                      <p>
                        Pre-registration date:
                        {{ school.pre_registration_date_str }} ,{{
                          school.pre_registration_start_time_str
                        }}-{{ school.pre_registration_end_time_str }}
                      </p>
                      <p>
                        Kick-off date: {{ school.kickoff_date_str }} ,{{
                          school.kickoff_starttime_str
                        }}-{{ school.kickoff_endtime_str }}
                      </p>
                      <p>
                        Special-giveway date:
                        {{ school.kickoff_date_golden_ball_date_strstr }} ,{{
                          school.golden_ball_start_time_str
                        }}-{{ school.golden_ball_end_time_str }}
                      </p>
                      <p>
                        Graduation date: {{ school.graduation_date_str }} ,{{
                          school.graduation_starttime_str
                        }}-{{ school.graduation_endtime_str }}
                      </p>
                      <p>Low Goal: {{ school.low_goal }}</p>
                      <p>Middle Goal: {{ school.middle_goal }}</p>
                      <p>Top Goal: {{ school.top_goal }}</p>
                      <p>Low Goal Purpose: {{ school.low_goal_purpose }}</p>
                      <p>
                        Middle Goal Purpose: {{ school.middle_goal_purpose }}
                      </p>
                      <p>Top Goal Purpose: {{ school.top_goal_purpose }}</p>
                      <p>Other Incentive:</p>
                      <span v-html="school.other_incentive"></span>
                      <br />
                      <p>Notes:</p>
                      <div v-html="school.notes"></div>
                      <p>
                        Money Raised: {{ school.orignal_total_fund_raised_50 }}
                      </p>
                      <p>Financial Goal: {{ school.school_goal_50 }}</p>
                    </li>
                  </ul>
                </div>

                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>Money Raised:</strong>
                  {{ this.campaignData.original_total_fund_raised_50 }}
                  <br />
                  <strong>Financial Goal:</strong>
                  {{ this.campaignData.total_goal_50 }}
                </div>
                <br />
                <br />
                <strong>Description: </strong>
                <div v-html="this.campaignData.special_message"></div>
                <br />
                <br />
                <div v-if="this.campaignData.campaign_type == 'school'">
                  <strong>School Goals And Incentives:</strong><br />
                  Low Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.low_goal
                  }}
                  <br />
                  Low Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.low_goal_purpose
                  }}
                  <br />
                  Middle Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal
                  }}
                  <br />
                  Middle Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.middle_goal_purpose
                  }}
                  <br />
                  Top Goal Condition:&nbsp;&nbsp;{{
                    this.campaignData.top_goal
                  }}
                  <br />
                  Top Goal Purpose:&nbsp;&nbsp;{{
                    this.campaignData.top_goal_purpose
                  }}
                  <br />
                  <br />
                  <strong>Other Incentives:</strong>
                  <div v-html="this.campaignData.other_incentive"></div>
                  <strong>School Notes: </strong>
                  <div v-html="this.campaignData.school_notes"></div>
                </div>
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:</strong
                >
                <br />
                <strong>Name:</strong>{{ this.campaignData.host_name }}
                <br />
                <strong>Email:</strong> {{ this.campaignData.host_email }}
                <br />
                <strong>Phone No.:</strong>{{ this.campaignData.host_phone }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <!-- <div class="text-center" v-if="this.selectedEvent.eventids == 3">
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <strong
                  >{{ this.assemblyData.assemble_date_str }},
                  {{ converTime(this.assemblyData.start_time) }}-{{
                    converTime(this.assemblyData.end_time)
                  }}
                  | {{ this.assemblyData.assemble_timezone }}</strong
                >&nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEventModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
               v-if="this.adminAccess!='Player'" >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div v-if="this.assemblyData.player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in playeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                <br />
                <strong>Category: </strong>Paid Assembly
                <br />
                <br />
                <strong>Description: </strong>{{ this.assemblyData.note }}
                <br />
                <br />
                <strong>school: </strong>{{ this.assemblyData.school }}
                <br />
                <strong>Address: </strong>{{ this.assemblyData.address }}
                <br />
                <strong>Organization: </strong
                >{{ this.assemblyData.organization }}
                <br />
                <strong>Designation: </strong
                >{{ this.assemblyData.designation }}
                <br />
                <strong>Event Support: </strong
                >{{ this.assemblyData.event_support }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>-->
          </v-card-text>
        </v-card>
      </v-dialog>
      <img
        src="@/assets/thingsToDo/loader5.gif"
        width="100"
        height="100"
        style="margin-left: 42%"
        v-if="loading && $vuetify.breakpoint.smAndUp"
      />
      <img
        src="@/assets/thingsToDo/loader5.gif"
        width="100"
        height="100"
        style="margin-left: 40%"
        v-if="loading && $vuetify.breakpoint.xsOnly"
      />
      <v-calendar
        ref="calendar"
        color="primary"
        :events="events"
        v-model="focus"
        :type="type"
        :event-overlap-mode="mode"
        @change="updateRange"
        @click:event="showEvent"
        @click:date="viewDay"
        style="height: auto"
      ></v-calendar>
      <div class="column-container">
        <ul>
          <li style="color: #ffcccb">Games</li>
          <li style="color: #e56e94">Hotel</li>
          <li style="color: #41a7f5">Free Assembly</li>
          <li style="color: #8c6ee3">Wizfit</li>
        </ul>
        <ul>
          <li style="color: #f9b4f6">Task</li>
          <li style="color: #a3c585">PTO</li>
          <li style="color: #27e600">Non Game Event</li>
          <li style="color: #a9a9a9">Unassigned</li>
        </ul>
        <ul>
          <li style="color: #fbc02d">ALLSTAR</li>
          <li style="color: #ca5cdd">EMPIRE</li>
          <li style="color: #b96a59">LEGENDS</li>
        </ul>
      </div>
    </div>
    <add-event-modal></add-event-modal>
    <player-availability></player-availability>
    <player-availability-event></player-availability-event>
    <player-availability-event-edit></player-availability-event-edit>
    <add-task-modal></add-task-modal>
    <add-filter-modal></add-filter-modal>
    <HotelInfoModal></HotelInfoModal>
    <delete-custom-event-modal
      @reload="getMonthCalendarData"
    ></delete-custom-event-modal>
    <delete-pto-modal @reload="getMonthCalendarData"></delete-pto-modal>
    <delete-hotel-modal @reload="getMonthCalendarData"></delete-hotel-modal>
  </v-app>
</template>
<script>
import {
  API_CALENDAR_GET_DATA,
  ADMIN_GAMES_GET_DETAIL,
  ASSEMBLY_SCHEDUAL_API_GET,
  GAME_HOTEL_GET_API,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
  API_PAID_EVENT_GET_DATA,
  API_PAID_EVENT_DELETE_DATA,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
import { ROUTER_URL } from "../../constants/urls";
import moment from "moment";
export default {
  name: "App",
  components: {
    AddEventModal: () => import("./AddEventModal/AddEventModal.vue"),
    AddTaskModal: () => import("./AddTaskModal/AddTaskModal.vue"),
    AddFilterModal: () => import("./AddFilterModal/AddFilterModal.vue"),
    PlayerAvailability: () =>
      import("./PlayerAvailability/PlayerAvailability.vue"),
    PlayerAvailabilityEvent: () =>
      import("./PlayerAvailability/PlayerAvailabilityEvent.vue"),
    PlayerAvailabilityEventEdit: () =>
      import("./PlayerAvailability/PlayerAvailabilityEventEdit.vue"),
    GameModal: () =>
      import("../GameManagement/AddEditGameModal/AddEditGameModal"),
    HotelInfoModal: () =>
      import("../GameManagement/HotelInfoModal/HotelInfoModal"),
    AssemblySchedualModal: () =>
      import(
        "../GameManagement/GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal"
      ),
    DeleteCustomEventModal: () =>
      import("./DeleteCustomEvent/DeleteCustomEventModal.vue"),
    DeletePtoModal: () => import("./DeletePtoModal/DeletePtoModal.vue"),
    DeleteHotelModal: () => import("./DeleteHotelModal/DeleteHotelModal.vue"),
  },
  data: () => {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      focus: "",
      events: [],
      mode: "stack",
      search: "",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },

        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: ["blue", "black", "orange"],
      today: new Date(),
      firstcalldate: "",
      type: "month",
      selectedOpen: false,
      selectedElement: undefined,
      selectedEvent: {},
      firstdate: "",
      lastdate: "",
      currentmonth: "",
      currentyear: "",
      monthcount: 0,
      nextflag: false,
      startMonth: "",
      eventcategoryid: localStorage.getItem("eventid"),
      teamID: localStorage.getItem("teamId"),
      playerID: localStorage.getItem("playerId"),
      startdatefmin: localStorage.getItem("startdatefilter"),
      enddatefmin: localStorage.getItem("enddatefilter"),
      eventSupport: localStorage.getItem("eventsupport"),
      salesrepresentative: localStorage.getItem("salesrep"),
      gameData: {},
      playerGameItems: [],
      gamedisplaydate: "",
      assemblydisplaydatess: "",
      assemblyData: {},
      assemblegameNotes: [],
      hotelData: {},
      hoteldisplaydatess: "",
      campaignData: {},
      schoolListData: [],
      schoolCampaignData: {},
      wizfitdisplaydate: "",
      gamehotalData: {},
      kickoffdate: "",
      goldballdate: "",
      graduationdate: "",
      ticketpricelist: [],
      ticketgameDataTable: { items: [] },
      teamNameDataTable: { items: [] },
      playeritems: [],
      playeritems1: [],
      tickettypeitems: [],
      preregistrationplayeritems: [],
      kickoffplayeritems: [],
      kickoffplayeritems1: [],
      goldballplayeritems: [],
      goldballplayeritems1: [],
      graduationplayeritems: [],
      graduationplayeritems1: [],
      ticketassemblyitems: [],
      hotelplayeritems: [],
      hotelplayeritems1: [],
      ptoDetail: {},
      customDetail: {},
      ptodisplayDates: "",
      customdisplayDates: "",
      customplayeritems: [],
      customplayeritems1: [],
      loading: false,
      showFlag: false,
      currentState: false,
      gameNotes: [],
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      // calendar Modal
      toggleAddEventModal: "calendarManagement/toggleModal",
      toggleAddTaskModal: "calendarManagement/toggleTaskModal",
      toggleAddFilterModal: "calendarManagement/toggleFilterModal",
      togglePreviewModal: "calendarManagement/togglePreviewsModal",
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      toggleAddEditViewCampaignModal: "campaignManagement/toggleModal",
      togglePlayerAvailabilityModal:
        "calendarManagement/togglePlayerAvailability",
      togglePlayerAvailabilityEventModal:
        "calendarManagement/togglePlayerAvailabilityEvent",
      togglePlayerAvailabilityEventEditModal:
        "calendarManagement/togglePlayerAvailabilityEventEdit",
      toggleDeleteCustomEventModal: "calendarManagement/toggleDeleteModal",
      toggleDeletePTOModal: "calendarManagement/toggleDeletePtoModal",
      toggleDeleteHotelModal: "calendarManagement/toggleDeleteHotelModal",
      showToast: "snackBar/showToast",
    }),
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    updateRange({ start, end }) {
      // const min = new Date(`${start.date}T24:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);

      const min = new Date(`${start.date}T23:59:59`);
      const max = new Date(`${end.date}T23:59:59`);
      this.firstdatemin = moment(min).format("YYYY-MM-DD");
      this.lastdatemin = moment(max).format("YYYY-MM-DD");
      this.startMonth = this.$refs.calendar.title;
      if (this.type == "month") {
        this.getMonthCalendarData();
      } else {
        this.getWeekDayCalendarData();
      }
    },
    getMonthCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.eventss = res.data.calendar_list;
        for (const items of this.eventss) {
          for (var a = 0; a < this.eventss.length; a++) {
            for (const itemsasem of this.eventss[a].assemblies) {
              if (items.assemble_id == itemsasem.id) {
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null &&
                  itemsasem.team_name != "ALLSTAR" &&
                  itemsasem.team_name != "EMPIRE" &&
                  itemsasem.team_name != "LEGENDS" &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null &&
                  itemsasem.team_name != "ALLSTAR" &&
                  itemsasem.team_name != "EMPIRE" &&
                  itemsasem.team_name != "LEGENDS" &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "ALLSTAR" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "ALLSTAR" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "EMPIRE" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "EMPIRE" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "LEGENDS" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "LEGENDS" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.player_list.map((player) => player.name) +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.player_list.map((player) => player.name) +
                    ")";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(Unassigned)";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#A9A9A9";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(Unassigned)";
                  // const startdate1 = items.start_date;
                  //const time = items.start_time;
                  // var dateTime1 = startdate1 + " " + time;
                  // const start = dateTime1;
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, color, id, eventids });
                }
              }
            }
          }
          /**   if (items.event_category == 6) {
            for (var p = 0; p < this.eventss.length; p++) {
              for (const itemspaid of this.eventss[p].assemblies) {
                if (items.assemble_id == itemspaid.id) {
                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team != null &&
                    itemspaid.player_list == null
                  ) {
                    const name =
                      itemspaid.city +
                     ", " +
                      itemspaid.assemble_state_paid +
                      "- PA(" +
                      itemspaid.team_name +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    const start = items.start_date;
                    const color = "#edc967a1";
                    const id = itemspaid.id;
                    this.events.push({ name, start, color, id });
                  }

                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team == null &&
                    itemspaid.player_list != null
                  ) {
                    const name =
                      itemspaid.city.toUpperCase() +
                     ", " +
                      itemspaid.assemble_state_paid +
                      "- PA(" +
                      itemspaid.player_list.map(player => player.name)+
                      ")";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    const start = items.start_date;
                    const color = "#edc967a1";
                    const id = itemspaid.id;
                    this.events.push({ name, start, color, id });
                  }

                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team == null &&
                    itemspaid.player_list == null
                  ) {
                    const name =
                      itemspaid.city.toUpperCase() +
                     ", " +
                      itemspaid.assemble_state_paid +
                      "- PA(Unassigned)";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    const start = items.start_date;
                    const color = "#A9A9A9";
                    const id = itemspaid.id;
                    this.events.push({ name, start, color, id });
                  }
                }
              }
            }
          }
          **/
          if (items.event_category == 8) {
            for (var q = 0; q < this.eventss.length; q++) {
              for (const itemspaidevens of this.eventss[q].paid_events) {
                if (items.paid_event_id == itemspaidevens.id) {
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name != "ALLSTAR" &&
                    itemspaidevens.team_name != "EMPIRE" &&
                    itemspaidevens.team_name != "LEGENDS" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "ALLSTAR" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "EMPIRE" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "LEGENDS" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }
                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list != null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.player_list.map((player) => player.name) +
                      " )";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#27e600";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }

                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(Unassigned)";
                    //const startdatepaid = items.start_date;
                    //const time = items.start_time;
                    //var dateTimepaid = startdatepaid + " " + time;

                    // const start = dateTimepaid;
                    if (itemspaidevens.end_date != null) {
                      const start = items.start_date;
                      const end = items.end_date;
                      const color = "#A9A9A9";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({
                        name,
                        start,
                        end,
                        color,
                        id,
                        eventids,
                      });
                    } else {
                      const start = items.start_date;
                      const color = "#A9A9A9";
                      const id = itemspaidevens.id;
                      const eventids = 8;
                      this.events.push({ name, start, color, id, eventids });
                    }
                  }
                }
              }
            }
          }

          for (var b = 0; b < this.eventss.length; b++) {
            for (const itemsasemh of this.eventss[b].hotel_detail) {
              if (items.hotel_id == itemsasemh.id) {
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list != null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list != null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#A9A9A9";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E15241";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }

                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const end = items.end_date;
                  const color = "#A9A9A9";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, end, color, id, eventids });
                }
              }
            }
          }
          if (items.event_category == 5) {
            const name = items.task_title;
            const start = items.start_date;
            const end = items.end_date;
            const color = "#F9B4F6";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          if (items.event_category == 7) {
            const name = "PTO-(" + items.player_name + ")";
            const start = items.start_date;
            const end = items.end_date;
            const color = "#a3c585";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          for (var i = 0; i < this.eventss.length; i++) {
            for (const itemgame of this.eventss[i].games) {
              if (items.game_id == itemgame.id) {
                if (
                  itemgame.team_name == null &&
                  itemgame.player_list != null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.player_list.map((player) => player.name) +
                    ")";
                  const start = itemgame.date;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "ALLSTAR" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const start = itemgame.date;
                  const color = "#FBC02D";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "EMPIRE" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const start = itemgame.date;
                  const color = "#ca5cdd";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "LEGENDS" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const start = itemgame.date;
                  const color = "#b96a59";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name != "ALLSTAR" &&
                  itemgame.team_name != "EMPIRE" &&
                  itemgame.team_name != "LEGENDS" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const start = itemgame.date;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }

                if (
                  itemgame.team_name == null &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(Unassigned)";
                  const start = itemgame.date;
                  const color = "#A9A9A9";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, color, id, eventids });
                }
              }
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name != "ALLSTAR" &&
            items.campaigns[0].kick_off_team_name != "EMPIRE" &&
            items.campaigns[0].kick_off_team_name != "LEGENDS"
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "ALLSTAR" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "EMPIRE" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "LEGENDS" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list != null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_player_list.map(
                  (player) => player.name
                ) +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - KO (" + "Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name != "ALLSTAR" &&
            items.campaigns[0].gold_ball_team_name != "EMPIRE" &&
            items.campaigns[0].gold_ball_team_name != "LEGENDS" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "ALLSTAR" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "EMPIRE" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "LEGENDS" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list != null &&
            items.campaigns[0].gold_ball_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_player_list.map(
                  (player) => player.name
                ) +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list == null &&
            items.campaigns[0].gold_ball_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - GB (" + "Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }

          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name != "ALLSTAR" &&
            items.campaigns[0].graduation_team_name != "EMPIRE" &&
            items.campaigns[0].graduation_team_name != "LEGENDS" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "ALLSTAR" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "EMPIRE" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "LEGENDS" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list != null &&
            items.campaigns[0].graduation_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_player_list.map(
                  (player) => player.name
                ) +
                ")";

            const start = items.start_date;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list == null &&
            items.campaigns[0].graduation_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - GR (" + "Unassigned)";

            const start = items.start_date;
            const color = "#A9A9A9";
            const id = items.campaign_id;
            const eventids = "2";
            this.events.push({ name, start, color, id, eventids });
          }
        }
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData.start_date = this.firstdatemin;
      formData.end_date = this.lastdatemin;
      if (this.startdatefmin) {
        formData.start_date = this.startdatefmin;
      }
      if (this.enddatefmin) {
        formData.end_date = this.enddatefmin;
      }
      formData.event_category_id = this.eventcategoryid;
      formData.event_support_id = this.eventSupport;
      formData.sales_rep_id = this.salesrepresentative;
      formData.player_id = this.playerID;
      if (this.teamID != "null") {
        formData.team_id = this.teamID;
      }
      localStorage.setItem("filterdata", this.eventcategoryid);

      //formData.show_all = "show_all";
      formData.search = this.search;
      Axios.request_GET(
        API_CALENDAR_GET_DATA,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getWeekDayCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.eventss = res.data.calendar_list;
        for (const items of this.eventss) {
          for (var a = 0; a < this.eventss.length; a++) {
            for (const itemsasem of this.eventss[a].assemblies) {
              if (items.assemble_id == itemsasem.id) {
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null &&
                  itemsasem.team_name != "ALLSTAR" &&
                  itemsasem.team_name != "EMPIRE" &&
                  itemsasem.team_name != "LEGENDS" &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate31 = items.start_date;
                  const time = items.start_time;
                  var dateTime31 = startdate31 + " " + time;
                  const start = dateTime31;
                  const faendtime3 = items.end_time;
                  var faenddateTime3 = startdate31 + " " + faendtime3;
                  const end = faenddateTime3;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null &&
                  itemsasem.team_name != "ALLSTAR" &&
                  itemsasem.team_name != "EMPIRE" &&
                  itemsasem.team_name != "LEGENDS" &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate31 = items.start_date;
                  const time = items.start_time;
                  var dateTime31a = startdate31 + " " + time;
                  const start = dateTime31a;
                  const faendtime3 = items.end_time;
                  var faenddateTime3a = startdate31 + " " + faendtime3;
                  const end = faenddateTime3a;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "ALLSTAR" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate32 = items.start_date;
                  const time = items.start_time;
                  var dateTime32 = startdate32 + " " + time;
                  const start = dateTime32;
                  const faendtime2 = items.end_time;
                  var faenddateTime2 = startdate32 + " " + faendtime2;
                  const end = faenddateTime2;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "ALLSTAR" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate32 = items.start_date;
                  const time = items.start_time;
                  var dateTime32a = startdate32 + " " + time;
                  const start = dateTime32a;
                  const faendtime2 = items.end_time;
                  var faenddateTime2a = startdate32 + " " + faendtime2;
                  const end = faenddateTime2a;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "EMPIRE" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate33 = items.start_date;
                  const time = items.start_time;
                  var dateTime33 = startdate33 + " " + time;
                  const start = dateTime33;
                  const faendtime1 = items.end_time;
                  var faenddateTime1 = startdate33 + " " + faendtime1;
                  const end = faenddateTime1;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "EMPIRE" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate33 = items.start_date;
                  const time = items.start_time;
                  var dateTime33a = startdate33 + " " + time;
                  const start = dateTime33a;
                  const faendtime1 = items.end_time;
                  var faenddateTime1a = startdate33 + " " + faendtime1;
                  const end = faenddateTime1a;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "LEGENDS" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate34 = items.start_date;
                  const time = items.start_time;
                  var dateTime34 = startdate34 + " " + time;
                  const start = dateTime34;
                  const faendtime = items.end_time;
                  var faenddateTime = startdate34 + " " + faendtime;
                  const end = faenddateTime;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "LEGENDS" &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate34 = items.start_date;
                  const time = items.start_time;
                  var dateTime34a = startdate34 + " " + time;
                  const start = dateTime34a;
                  const faendtime = items.end_time;
                  var faenddateTimea = startdate34 + " " + faendtime;
                  const end = faenddateTimea;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.player_list.map((player) => player.name) +
                    ")";
                  const startdate35 = items.start_date;
                  const time = items.start_time;
                  var dateTime35 = startdate35 + " " + time;
                  const start = dateTime35;
                  const faendtime5 = items.end_time;
                  var faenddateTime5 = startdate35 + " " + faendtime5;
                  const end = faenddateTime5;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(" +
                    itemsasem.player_list.map((player) => player.name) +
                    ")";
                  const startdate35 = items.start_date;
                  const time = items.start_time;
                  var dateTime35a = startdate35 + " " + time;
                  const start = dateTime35a;
                  const faendtime5 = items.end_time;
                  var faenddateTime5a = startdate35 + " " + faendtime5;
                  const end = faenddateTime5a;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == true
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA " +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(Unassigned)";
                  const startdate36 = items.start_date;
                  const time = items.start_time;
                  var dateTime36 = startdate36 + " " + time;
                  const start = dateTime36;
                  const faendtime6 = items.end_time;
                  var faenddateTime6 = startdate36 + " " + faendtime6;
                  const end = faenddateTime6;
                  const color = "#A9A9A9";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null &&
                  itemsasem.approved == false
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    ", " +
                    itemsasem.assemble_state +
                    "-FA " +
                    "(" +
                    itemsasem.sequence +
                    ")" +
                    "(Unassigned)";
                  const startdate36 = items.start_date;
                  const time = items.start_time;
                  var dateTime36a = startdate36 + " " + time;
                  const start = dateTime36a;
                  const faendtime6 = items.end_time;
                  var faenddateTime6a = startdate36 + " " + faendtime6;
                  const end = faenddateTime6a;
                  const color = "#E15241";
                  const id = items.assemble_id;
                  const eventids = "3";
                  this.events.push({ name, start, end, color, id, eventids });
                }
              }
            }
          }

          if (items.event_category == 8) {
            for (var q = 0; q < this.eventss.length; q++) {
              for (const itemspaidevens of this.eventss[q].paid_events) {
                if (items.paid_event_id == itemspaidevens.id) {
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name != "ALLSTAR" &&
                    itemspaidevens.team_name != "EMPIRE" &&
                    itemspaidevens.team_name != "LEGENDS" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    const startdatecustom = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom = startdatecustom + " " + timecustom;
                    const start = dateTimecustom;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom =
                      startdatecustom + " " + endtimecustom;
                    const end = enddateTimecustom;
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "ALLSTAR" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    const startdatecustom81 = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom81 = startdatecustom81 + " " + timecustom;
                    const start = dateTimecustom81;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom81 =
                      startdatecustom81 + " " + endtimecustom;
                    const end = enddateTimecustom81;
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "EMPIRE" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    const startdatecustom82 = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom82 = startdatecustom82 + " " + timecustom;
                    const start = dateTimecustom82;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom82 =
                      startdatecustom82 + " " + endtimecustom;
                    const end = enddateTimecustom82;
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "LEGENDS" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                    const startdatecustom83 = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom83 = startdatecustom83 + " " + timecustom;
                    const start = dateTimecustom83;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom83 =
                      startdatecustom83 + " " + endtimecustom;
                    const end = enddateTimecustom83;
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }
                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list != null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.player_list.map((player) => player.name) +
                      " )";
                    const startdatecustom84 = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom84 = startdatecustom84 + " " + timecustom;
                    const start = dateTimecustom84;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom84 =
                      startdatecustom84 + " " + endtimecustom;
                    const end = enddateTimecustom84;
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }

                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =
                      itemspaidevens.event_name +
                      "- " +
                      itemspaidevens.city.toUpperCase() +
                      ", " +
                      itemspaidevens.state_name +
                      "-(Unassigned)";
                    const startdatecustom85 = itemspaidevens.start_date;
                    const timecustom = itemspaidevens.start_time;
                    var dateTimecustom85 = startdatecustom85 + " " + timecustom;
                    const start = dateTimecustom85;
                    const endtimecustom = itemspaidevens.end_time;
                    var enddateTimecustom85 =
                      startdatecustom85 + " " + endtimecustom;
                    const end = enddateTimecustom85;
                    const color = "#A9A9A9";
                    const id = itemspaidevens.id;
                    const eventids = 8;
                    this.events.push({ name, start, end, color, id, eventids });
                  }
                }
              }
            }
          }

          for (var b = 0; b < this.eventss.length; b++) {
            for (const itemsasemh of this.eventss[b].hotel_detail) {
              if (items.hotel_id == itemsasemh.id) {
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list != null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list != null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const color = "#E15241 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == true
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#A9A9A9";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list == null &&
                  itemsasemh.approved == false
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#E15241";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name != "ALLSTAR" &&
                  itemsasemh.team_name != "EMPIRE" &&
                  itemsasemh.team_name != "LEGENDS" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "EMPIRE" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.team_name == "LEGENDS" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    ", " +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#A9A9A9";
                  const id = items.hotel_id;
                  const eventids = "4";
                  this.events.push({ name, start, color, id, eventids });
                }
              }
            }
          }
          if (items.event_category == 5) {
            const name = items.task_title;
            const start = items.start_date;
            const end = items.end_date;
            const color = "#F9B4F6";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          if (items.event_category == 7) {
            const name = "PTO-(" + items.player_name + ")";

            if (items.start_time != null && items.end_time != null) {
              const startdatepto = items.start_date;
              const time = items.start_time;
              // conversion
              var dateTimepto = startdatepto + " " + time;
              const start = dateTimepto;
              const endtime = items.end_time;
              var enddateTimepto = startdatepto + " " + endtime;
              const end = enddateTimepto;
              const color = "#a3c585";
              const id = items.id;
              this.events.push({ name, start, end, color, id });
            } else {
              const start = items.start_date;
              const end = items.end_date;
              const color = "#a3c585";
              const id = items.id;
              this.events.push({ name, start, end, color, id });
            }
          }
          for (var i = 0; i < this.eventss.length; i++) {
            for (const itemgame of this.eventss[i].games) {
              if (items.game_id == itemgame.id) {
                if (
                  itemgame.team_name == null &&
                  itemgame.player_list != null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.player_list.map((player) => player.name) +
                    ")";
                  const startdate = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame = startdate + " " + time;
                  const start = dateTimegame;
                  const endtime = itemgame.end_time;
                  var enddateTimegame = startdate + " " + endtime;
                  const end = enddateTimegame;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "ALLSTAR" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate11 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame11 = startdate11 + " " + time;
                  const start = dateTimegame11;
                  const endtime = itemgame.end_time;
                  var enddateTimegame11 = startdate11 + " " + endtime;
                  const end = enddateTimegame11;
                  const color = "#FBC02D";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "EMPIRE" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate12 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame12 = startdate12 + " " + time;
                  const start = dateTimegame12;
                  const endtime = itemgame.end_time;
                  var enddateTimegame12 = startdate12 + " " + endtime;
                  const end = enddateTimegame12;
                  const color = "#ca5cdd";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "LEGENDS" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate13 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame13 = startdate13 + " " + time;
                  const start = dateTimegame13;
                  const endtime = itemgame.end_time;
                  var enddateTimegame13 = startdate13 + " " + endtime;
                  const end = enddateTimegame13;
                  const color = "#b96a59";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name != "ALLSTAR" &&
                  itemgame.team_name != "EMPIRE" &&
                  itemgame.team_name != "LEGENDS" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate14 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame14 = startdate14 + " " + time;
                  const start = dateTimegame14;
                  const endtime = itemgame.end_time;
                  var enddateTimegame14 = startdate14 + " " + endtime;
                  const end = enddateTimegame14;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }

                if (
                  itemgame.team_name == null &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    ", " +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(Unassigned)";
                  const startdate15 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame15 = startdate15 + " " + time;
                  const start = dateTimegame15;
                  const endtime = itemgame.end_time;
                  var enddateTimegame15 = startdate15 + " " + endtime;
                  const end = enddateTimegame15;
                  const color = "#A9A9A9";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start, end, color, id, eventids });
                }
              }
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name != "ALLSTAR" &&
            items.campaigns[0].kick_off_team_name != "EMPIRE" &&
            items.campaigns[0].kick_off_team_name != "LEGENDS"
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startko = items.start_date;
              const timeko = items.start_time;
              var dateTimeko = startko + " " + timeko;
              const start = dateTimeko;
              const endko = items.start_date;
              const endtimeko = items.end_time;
              var dateTimeendko = endko + " " + endtimeko;
              const end = dateTimeendko;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "ALLSTAR" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startko1 = items.start_date;
              const timeko = items.start_time;
              var dateTimeko1 = startko1 + " " + timeko;
              const start = dateTimeko1;
              const endko = items.start_date;
              const endtimeko1 = items.end_time;
              var dateTimeendko1 = endko + " " + endtimeko1;
              const end = dateTimeendko1;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#FBC02D";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "EMPIRE" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startko12 = items.start_date;
              const timeko = items.start_time;
              var dateTimeko12 = startko12 + " " + timeko;
              const start = dateTimeko12;
              const endko = items.start_date;
              const endtimeko12 = items.end_time;
              var dateTimeendko12 = endko + " " + endtimeko12;
              const end = dateTimeendko12;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#ca5cdd";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team_name != null &&
            items.campaigns[0].kick_off_team_name == "LEGENDS" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startko13 = items.start_date;
              const timeko = items.start_time;
              var dateTimeko13 = startko13 + " " + timeko;
              const start = dateTimeko13;
              const endko = items.start_date;
              const endtimeko13 = items.end_time;
              var dateTimeendko13 = endko + " " + endtimeko13;
              const end = dateTimeendko13;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#b96a59";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list != null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                items.campaigns[0].kick_off_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_player_list.map(
                  (player) => player.name
                ) +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startko14 = items.start_date;
              const timeko = items.start_time;
              var dateTimeko14 = startko14 + " " + timeko;
              const start = dateTimeko14;
              const endko = items.start_date;
              const endtimeko14 = items.end_time;
              var dateTimeendko14 = endko + " " + endtimeko14;
              const end = dateTimeendko14;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - KO (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - KO (" + "Unassigned)";
            if (items.start_time != null && items.end_time != null) {
              const startko15 = items.start_date;
              const timeko = items.start_time;
              var dateTimeko15 = startko15 + " " + timeko;
              const start = dateTimeko15;
              const endko = items.start_date;
              const endtimeko15 = items.end_time;
              var dateTimeendko15 = endko + " " + endtimeko15;
              const end = dateTimeendko15;
              const color = "#A9A9A9";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name != "ALLSTAR" &&
            items.campaigns[0].gold_ball_team_name != "EMPIRE" &&
            items.campaigns[0].gold_ball_team_name != "LEGENDS" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgb = items.start_date;
              const timegb = items.start_time;
              var dateTimegb = startgb + " " + timegb;
              const start = dateTimegb;
              const endgb = items.start_date;
              const endtimegb = items.end_time;
              var dateTimeendgb = endgb + " " + endtimegb;
              const end = dateTimeendgb;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "ALLSTAR" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgb1 = items.start_date;
              const timegb = items.start_time;
              var dateTimegb1 = startgb1 + " " + timegb;
              const start = dateTimegb1;
              const endgb = items.start_date;
              const endtimegb1 = items.end_time;
              var dateTimeendgb1 = endgb + " " + endtimegb1;
              const end = dateTimeendgb1;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#FBC02D";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "EMPIRE" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgb12 = items.start_date;
              const timegb = items.start_time;
              var dateTimegb12 = startgb12 + " " + timegb;
              const start = dateTimegb12;
              const endgb = items.start_date;
              const endtimegb12 = items.end_time;
              var dateTimeendgb12 = endgb + " " + endtimegb12;
              const end = dateTimeendgb12;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#ca5cdd";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team_name != null &&
            items.campaigns[0].gold_ball_team_name == "LEGENDS" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgb13 = items.start_date;
              const timegb = items.start_time;
              var dateTimegb13 = startgb13 + " " + timegb;
              const start = dateTimegb13;
              const endgb = items.start_date;
              const endtimegb13 = items.end_time;
              var dateTimeendgb13 = endgb + " " + endtimegb13;
              const end = dateTimeendgb13;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#b96a59";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list != null &&
            items.campaigns[0].gold_ball_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                items.campaigns[0].gold_ball_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_player_list.map(
                  (player) => player.name
                ) +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgb14 = items.start_date;
              const timegb = items.start_time;
              var dateTimegb14 = startgb14 + " " + timegb;
              const start = dateTimegb14;
              const endgb = items.start_date;
              const endtimegb14 = items.end_time;
              var dateTimeendgb14 = endgb + " " + endtimegb14;
              const end = dateTimeendgb14;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list == null &&
            items.campaigns[0].gold_ball_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GB (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - GB (" + "Unassigned)";
            if (items.start_time != null && items.end_time != null) {
              const startgb15 = items.start_date;
              const timegb = items.start_time;
              var dateTimegb15 = startgb15 + " " + timegb;
              const start = dateTimegb15;
              const endgb = items.start_date;
              const endtimegb15 = items.end_time;
              var dateTimeendgb15 = endgb + " " + endtimegb15;
              const end = dateTimeendgb15;
              const color = "#A9A9A9";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name != "ALLSTAR" &&
            items.campaigns[0].graduation_team_name != "EMPIRE" &&
            items.campaigns[0].graduation_team_name != "LEGENDS" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgr = items.start_date;
              const timegr = items.start_time;
              var dateTimegr = startgr + " " + timegr;
              const start = dateTimegr;
              const endgr = items.start_date;
              const endtimegr = items.end_time;
              var dateTimeendgr = endgr + " " + endtimegr;
              const end = dateTimeendgr;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "ALLSTAR" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgr1 = items.start_date;
              const timegr = items.start_time;
              var dateTimegr1 = startgr1 + " " + timegr;
              const start = dateTimegr1;
              const endgr = items.start_date;
              const endtimegr1 = items.end_time;
              var dateTimeendgr1 = endgr + " " + endtimegr1;
              const end = dateTimeendgr1;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#FBC02D";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "EMPIRE" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgr12 = items.start_date;
              const timegr = items.start_time;
              var dateTimegr12 = startgr12 + " " + timegr;
              const start = dateTimegr12;
              const endgr = items.start_date;
              const endtimegr12 = items.end_time;
              var dateTimeendgr12 = endgr + " " + endtimegr12;
              const end = dateTimeendgr12;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#ca5cdd";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team_name != null &&
            items.campaigns[0].graduation_team_name == "LEGENDS" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_team_name +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgr13 = items.start_date;
              const timegr = items.start_time;
              var dateTimegr13 = startgr13 + " " + timegr;
              const start = dateTimegr13;
              const endgr = items.start_date;
              const endtimegr13 = items.end_time;
              var dateTimeendgr13 = endgr + " " + endtimegr13;
              const end = dateTimeendgr13;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#b96a59";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list != null &&
            items.campaigns[0].graduation_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                items.campaigns[0].graduation_player_list.map(
                  (player) => player.name
                ) +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_player_list.map(
                  (player) => player.name
                ) +
                ")";
            if (items.start_time != null && items.end_time != null) {
              const startgr14 = items.start_date;
              const timegr = items.start_time;
              var dateTimegr14 = startgr14 + " " + timegr;
              const start = dateTimegr14;
              const endgr = items.start_date;
              const endtimegr14 = items.end_time;
              var dateTimeendgr14 = endgr + " " + endtimegr14;
              const end = dateTimeendgr14;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list == null &&
            items.campaigns[0].graduation_team_name == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.city +
                ", " +
                items.state +
                "  - GR (" +
                "Unassigned)"
              : " WF -" + items.district_name + "  - GR (" + "Unassigned)";
            if (items.start_time != null && items.end_time != null) {
              const startgr15 = items.start_date;
              const timegr = items.start_time;
              var dateTimegr15 = startgr15 + " " + timegr;
              const start = dateTimegr15;
              const endgr = items.start_date;
              const endtimegr15 = items.end_time;
              var dateTimeendgr15 = endgr + " " + endtimegr15;
              const end = dateTimeendgr15;
              const color = "#A9A9A9";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, end, color, id, eventids });
            } else {
              const start = items.start_date;
              const color = "#8c6ee39e";
              const id = items.campaign_id;
              const eventids = "2";
              this.events.push({ name, start, color, id, eventids });
            }
          }
        }
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData.start_date = this.firstdatemin;
      formData.end_date = this.lastdatemin;
      if (this.startdatefmin) {
        formData.start_date = this.startdatefmin;
      }
      if (this.enddatefmin) {
        formData.end_date = this.enddatefmin;
      }
      formData.event_category_id = this.eventcategoryid;
      formData.event_support_id = this.eventSupport;
      formData.sales_rep_id = this.salesrepresentative;
      formData.player_id = this.playerID;
      if (this.teamID != "null") {
        formData.team_id = this.teamID;
      }
      localStorage.setItem("filterdata", this.eventcategoryid);
      //formData.show_all = "show_all";
      formData.search = this.search;
      Axios.request_GET(
        API_CALENDAR_GET_DATA,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    newWeekCalender() {
      console.log("insadadsa");
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.weekcalendarManagement.name,
      });
    },
    newDayCalender() {
      console.log("insadadsa");
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.daycalendarManagement.name,
      });
    },
    visitGamePanelHandler(game) {
      // alert(this.selectedEvent.id);
      const { id, school } = game;
      console.log(id, school);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.dashboard.name,
        query: { school: school, game_id: this.selectedEvent.id },
      });
      window.open(routerData.href, "_blank");
    },
    visitAllAssembly(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gameallassembly.name,
        query: { game_id: game },
      });
      window.open(routerData.href, "_blank");
    },
    ShowAll() {
      this.showFlag = true;
      this.currentState = true;
      this.getMonthCalendarData();
      //  this.getWeekDayCalendarData();
    },
    myEvents() {
      location.reload();
    },
    getGameDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.gameData = res.data.game_detail;
        this.gameNotes = this.gameData.game_notes;
        this.playerGameItems = this.gameData.player_confirmations;
        if (this.gameData.ticket_data != null) {
          this.tickettypeitems = this.gameData.ticket_data;
        }
        //  alert("dsds")
        if (this.gameData.hotel_data != null) {
          this.gamehotalData = this.gameData.hotel_data[0];
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.selectedEvent.id;
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getAssemblyDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.date = res.data.assemble_detail.date;
        this.start_time = res.data.assemble_detail.start_time_str;
        this.end_time = res.data.assemble_detail.end_time_str;
        this.noOfStudents = res.data.assemble_detail.total_student;
        this.noOfAssemblies = res.data.assemble_detail.total_assemblies;
        this.name = res.data.assemble_detail.driver_name;
        this.mobile = res.data.assemble_detail.phone;
        this.email = res.data.assemble_detail.email;
        this.notes = res.data.assemble_detail.note;
        this.loading = false;

        this.assemblyData = res.data.assemble_detail;
        this.assemblegameNotes = this.assemblyData.game_notes;
        if (this.assemblyData.ticket_data != null) {
          this.ticketassemblyitems = this.assemblyData.ticket_data;
        }
        console.log("this.ticketassemblyitems", this.ticketassemblyitems);
        if (this.assemblyData.player_confirmations.length != 0) {
          this.playeritems1 = this.assemblyData.player_confirmations;
        }
        if (this.assemblyData.player_list != null) {
          this.playeritems = this.assemblyData.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] = this.selectedEvent.id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHotelDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.loading = false;
        this.hotelData = res.data.hotel_detail;
        var hoteldisplaydatess = new Date(this.hotelData.checking_in_date);
        var datesssh = new Date(hoteldisplaydatess);
        this.hoteldisplaydatess = datesssh.toDateString();
        if (this.hotelData.player_confirmations.length != 0) {
          this.hotelplayeritems1 = this.hotelData.player_confirmations;
        }
        if (this.hotelData.player_list != null) {
          this.hotelplayeritems = this.hotelData.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["hotel_id"] = this.selectedEvent.id;
      Axios.request_GET(
        GAME_HOTEL_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getCampaignDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.campaignData = res.data.campaign_detail;
        this.schoolListData = res.data.school_list;
        var wizfitdatess = new Date(this.campaignData.start_date);
        var datewizfit = new Date(wizfitdatess);
        this.wizfitdisplaydate = datewizfit.toDateString();
        if (this.campaignData.kick_off_player_list != null) {
          this.kickoffplayeritems = this.campaignData.kick_off_player_list;
        }
        if (this.campaignData.kickoff_player_confirmations.length != 0) {
          this.kickoffplayeritems1 =
            this.campaignData.kickoff_player_confirmations;
        }
        if (this.campaignData.gold_ball_player_list != null) {
          this.goldballplayeritems = this.campaignData.gold_ball_player_list;
        }
        if (this.campaignData.goldball_player_confirmations.length != 0) {
          this.goldballplayeritems1 =
            this.campaignData.goldball_player_confirmations;
        }
        if (this.campaignData.graduation_player_list != null) {
          this.graduationplayeritems = this.campaignData.graduation_player_list;
        }
        if (this.campaignData.grad_player_confirmations.length != 0) {
          this.graduationplayeritems1 =
            this.campaignData.grad_player_confirmations;
        }
        if (this.campaignData.pre_registration_player_list != null) {
          this.preregistrationplayeritems =
            this.campaignData.pre_registration_player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedEvent.id;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getptoDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ptoDetail = res.data.calendar_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        "/calendar/" + this.selectedEvent.id + "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getcustomeventDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.customDetail = res.data.paid_event_detail;
        if (this.customDetail.player_confirmations.length != 0) {
          this.customplayeritems1 = this.customDetail.player_confirmations;
        }
        if (this.customDetail.player_list != null) {
          this.customplayeritems = this.customDetail.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["paid_event_id"] = this.selectedEvent.id;
      Axios.request_GET(
        API_PAID_EVENT_GET_DATA,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    dateChange(date) {
      console.log(date);
    },
    setToday() {
      this.focus = "";
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        //  alert(this.selectedEvent.color);
        this.chekinclude = this.selectedEvent.name;
        // alert(this.chekinclude.includes("KO"));
        // alert(this.selectedEvent.id);
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
      if (this.selectedEvent.eventids == 1) {
        // alert(this.selectedEvent.eventids);
        this.assemblyData = {};
        this.ptoDetail = {};
        this.hotelData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getGameDetail();
      }
      if (this.selectedEvent.eventids == 3) {
        // alert(this.selectedEvent.id);
        this.gameData = {};
        this.ptoDetail = {};
        this.hotelData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getAssemblyDetail();
      }
      if (this.selectedEvent.color == "#edc967a1") {
        // alert(this.selectedEvent.id);
        this.gameData = {};
        this.ptoDetail = {};
        this.hotelData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getAssemblyDetail();
      }
      if (this.selectedEvent.eventids == 4) {
        // alert(this.selectedEvent.id);
        this.assemblyData = {};
        this.ptoDetail = {};
        this.gameData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getHotelDetail();
      }
      if (this.selectedEvent.eventids == 2) {
        this.assemblyData = {};
        this.ptoDetail = {};
        this.gameData = {};
        this.customDetail = {};
        this.hotelData = {};
        this.getCampaignDetail();
      }
      if (this.selectedEvent.color == "#a3c585") {
        this.assemblyData = {};
        this.hotelData = {};
        this.gameData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getptoDetail();
      }
      if (this.selectedEvent.color == "#F9B4F6") {
        this.assemblyData = {};
        this.hotelData = {};
        this.gameData = {};
        this.customDetail = {};
        this.campaignData = {};
        this.getptoDetail();
      }
      if (this.selectedEvent.eventids == 8) {
        this.assemblyData = {};
        this.hotelData = {};
        this.gameData = {};
        this.ptoDetail = {};
        this.campaignData = {};
        this.getcustomeventDetail();
      }
    },
    closeEventPopup() {
      this.selectedOpen = false;
    },

    editCampaign() {
      let routerData = this.$router.resolve({
        // name: ROUTER_URL.adminPanel.children.newCampaign.name,
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: this.selectedEvent.id },
      });
      window.open(routerData.href, "_blank");
    },
    newListCalender() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.listcalendarManagement.name,
      });
    },
    deleteTaskData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {};
      let formData = {};
      Axios.request_DELETE(
        "/calendar/" + this.selectedEvent.id + "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    deleteCustomEventData() {
      console.log("delete");
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {};
      let formData1 = {};
      formData1["id"] = this.selectedEvent.id;
      Axios.request_DELETE(
        API_PAID_EVENT_DELETE_DATA,
        formData1,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    playerListView() {
      let routerData = this.$router.resolve({
        name: ROUTER_URL.adminPanel.children.playerListCalendar.name,
      });
      window.open(routerData.href, "_blank");
    },
  },
  computed: {
    selectedEventStart() {
      return this.selectedEvent && this.selectedEvent.start;
    },
    selectedEventEnd() {
      return this.selectedEvent && this.selectedEvent.end;
    },
    formattedSchoolGroups() {
      return this.assemblyData.assemble_school_groups
        .map(
          (group, index) => `
            <br>
            <h4>School #${index + 1}</h4>
            <p><strong>Assembly or Cafeteria Visit:</strong> ${group.visit}</p>
            <p><strong >Name Of School : </strong>${group.school_name}</p>
            <p><strong>Time:</strong> ${group.start_time_str}-${
            group.end_time_str
          }</p>
            <p><strong>Address:</strong> ${group.school_address}</p>
             <p><strong>Phone #:</strong> ${group.principal_phone}</p>
             <p><strong>Principal Name:</strong> ${group.principal_name}</p>
            <p><strong>Email Address of Principal or Contact at School:</strong> ${
              group.principal_email
            }</p>
            <p><strong>Number of Students:</strong> ${
              group.number_of_students
            }</p>
            <p><strong>Grades:</strong> ${group.grades}</p>
              <p><strong>Location Within School:</strong> ${
                group.location_in_school
              }</p>
              <p><strong>Note:</strong>  ${group.note}</p>
              
          
            <hr>
          `
        )
        .join("");
    },
  },
};
</script>
