<template>
  <div class="px-7 py-10">
    <v-card class="mt-10">
      <v-card-title class="py-3">
        <v-row justify="center">
          <v-col> </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="6" md="3" class="pl-3">
            <v-autocomplete
              label="Category"
              outlined
              dense
              v-model="category"
              :items="categoryList"
              item-value="id"
              item-text="category_name"
              class="formFields"
              color="#7253CF"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <div class="card-text">
            <p class="heading" style="margin-left: 11px">Upload File</p>
          </div>
          <br />
        </v-row>
        <v-row>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="title"
              label="Title"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="note"
              label="Note"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters >
          <v-col cols="12" class="pt-8 px-6"> </v-col>
          <v-col cols="6" v-if="this.category!=9 && !this.$route.query.id">
          <label style="font-size: medium;">Upload File</label>
            <v-file-input
              v-model="file"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
              color="#7254CF"
              dense
              clearable
            >
            </v-file-input>
          </v-col>
          <v-col cols="6" v-if="this.category==9">
          <v-text-field
              outlined
              dense
              v-model="video_url"
              label="Video URL"
              color="#7253CF"
              class="formFields"
            ></v-text-field>
              </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              @click="submitBulkUpload1"
              :loading="submitLoading"
           v-if="this.category==9" >
              <span>Submit</span>
            </v-btn>
             <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              @click="submitBulkUpload"
              :loading="submitLoading"
           v-else>
              <span>Submit</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  API_RESOURCE_POST,
  GET_RESOURCES_CATEGORYLIST,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "Upload",
  data() {
    return {
      category: "",
      categoryList: [],
      file: {},
      routeName: "",
      user_id: localStorage.getItem("user_id"),
      note: "",
      video_url:"",
      title: "",
      formLoading: false,
      submitLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),

    submitBulkUpload() {
      const self = this;
      this.submitLoading = true;
      const successHandler = (res) => {
        self.formLoading = false;
        console.log(res);
        this.showToast({
          message: "Uploded",
          color: "s",
        });
        this.file = {};
        this.$router.go(-1);
        this.submitLoading = false;
      };
      const failureHandler = (res) => {
        this.submitLoading = false;
        console.log(res.data);
        this.showToast({
          message: "something went wrong",
          color: "e",
        });
      };
      let formData = new FormData();
       if(!this.$route.query.id){
      if (this.file instanceof File) {
     
        formData.append("document", this.file);
        }
        }
        formData.append("notes", this.note);
        formData.append("title", this.title);
        formData.append("user", this.user_id);
        formData.append("category", this.category);
        console.log("in school");
        if (this.$route.query.id) {
          Axios.request_PATCH(
            "/school/school_resource/" + this.$route.query.id + "/",
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          Axios.request_POST(
            API_RESOURCE_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      
    },

      submitBulkUpload1() {
      const self = this;
      this.submitLoading = true;
      const successHandler = (res) => {
        self.formLoading = false;
        console.log(res);
        this.showToast({
          message: "Uploded",
          color: "s",
        });
        this.$router.go(-1);
        this.submitLoading = false;
      };
      const failureHandler = (res) => {
        this.submitLoading = false;
        console.log(res.data);
        this.showToast({
          message: "something went wrong",
          color: "e",
        });
      };
      let formData = new FormData();
        formData.append("notes", this.note);
        formData.append("title", this.title);
        formData.append("user", this.user_id);
        formData.append("category", this.category);
         formData.append("video_url", this.video_url);
        console.log("in school");
        if (this.$route.query.id) {
          Axios.request_PATCH(
            "/school/school_resource/" + this.$route.query.id + "/",
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          Axios.request_POST(
            API_RESOURCE_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
      }
    },
    getCategoryList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.categoryList = data.category_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson.search = "Wizfit";
      return Axios.request_GET(
        GET_RESOURCES_CATEGORYLIST,

        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    getDetail() {
      const self = this;
      const successHandler = (res) => {
        self.title = res.data.title;
        self.note = res.data.notes;
        self.document = res.data.file_name;
        self.category = res.data.category;
        self.video_url=res.data.video_url;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        "/school/school_resource/" + this.$route.query.id,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail();
    }
    this.getCategoryList();
  },
  beforeDestroy() {
    this.resetRoute();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
}
</style>
