<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
  
    <div class="table-div">
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="onboardtable.headers"
        :items="onboardtable.items"
        :loading="onboardtable.loading"
        :items-per-page="
          onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :options.sync="dataOptions"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px;height: 70px;">
            <td class="text-center row-item">
              <div>{{ row.item.school_name }}</div>
            </td>
            <td class="text-center row-item">
              <div v-if="row.item.principal_video == false" ><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else-if="row.item.principal_video == true" ><img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
            </td>
            <td class="text-center row-item">
              <div v-if="row.item.incentives_status == false" ><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else-if="row.item.incentives_status == true "  > <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
            </td>
            <td class="text-center row-item">
              <div v-if="row.item.raising_money_purpose == null"><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else-if="row.item.raising_money_purpose == 'null'"><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else> <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
                
            </td>
            <td class="text-center row-item">
              <div v-if="row.item.teacher_list == false" ><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else > <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
            </td>
          
            <td class="text-center row-item">
              <div style="font-weight: 700"> {{ row.item.pre_registration_date_str
               }}</div><div>{{
                row.item.pre_registration_start_time_str
              }}</div>
            </td>
            <td class="text-center row-item">
              <div style="font-weight: 700"> {{ row.item.kickoff_date_str }}</div><div>{{ row.item.kickoff_starttime_str }}</div>
            </td>
            <td class="text-center row-item">
              <div style="font-weight: 700"> {{ row.item.golden_ball_date_str }}</div><div>{{
                row.item.golden_ball_start_time_str
              }}</div>
            </td>
            <td class="text-center row-item">
               <div style="font-weight: 700">{{ row.item.graduation_date_str }}</div><div>{{ row.item.graduation_starttime_str }}</div>
            </td>
            <td class="text-center row-item"><div style="font-weight: 700">{{row.item.donation_close_date_str}}</div></td>
              <td class="text-center row-item">
              <div style="font-weight: 700">{{row.item.teacher_training_date_str}}</div><div>{{row.item.teacher_training_time_str}}</div>
            </td>
            <td class="text-center row-item">-</td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="onboardtable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        onboardtable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>
                  <div class="alignSelf-center">
                    {{
                      onboardtable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      onboardtable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      onboardtable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      onboardtable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="onboardtable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    onboardtable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  onboardtable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  API_ADMIN_GET_CAMPAIGN_ONBOARDLIST,
  API_DONATION_REPORT_GENERATE,
} from "@/constants/APIUrls";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions } from "vuex";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import _ from "lodash";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../constants/urls";
import Helper from "@/helper";
export default {
  name: "CampaignManagement",
  components: {},
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      onboardtable: {
        headers: [
          {
            width: "8%",
            sortable: true,
            text: "Organization Name & City/State",
            align: "center",
            value: "campaign_status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "5%",
            text: "Principal Video complete",
            align: "center",
            sortable: true,
            value: "name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "5%",
            text: "School Incentives Complete",
            align: "center",
            sortable: true,
            value: "campaign_type",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "5%",
            sortable: true,
            text: "What school is raising money for",
            align: "center",
            value: "total_fund_raised",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "5%",
            sortable: true,
            text: "Teacher List Complete",
            value: "total_students_registered",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },

      
          {
            width: "10%",
            sortable: true,
            text: "Pre-Registration Zoom date & Time",
            value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: true,
            text: "Kickoff Assembly date & times",
            value: "sms_invites",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: true,
            text: "Gold Ball raffle date and time",
            value: "social_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: false,
            text: "Graduation Celebration AssembliesDate & times",
            // value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "8%",
            sortable: false,
            text: "Donations Close date",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
              {
            width: "10%",
            sortable: true,
            text: "WizFit Shuffle Teacher Zoom Date & Time",
            value: "total_videos_watched",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "8%",
            sortable: false,
            text: "  Game Date (if game)& event support rep name",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      status: "ONGOING",
      notificationModal: {
        flag: false,
      },
    };
  },
  created() {
    this.debounceGetCampaignManagementData = _.debounce(
      this.getCampaignManagementData,
      500
    );
  },
  computed: {},
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "onboardtable.page_info.page_number": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "onboardtable.page_info.detail_count": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in SEARCH
    "onboardtable.search": function () {
      this.debounceGetCampaignManagementData();
    },
    "dataOptions.sortBy": function () {
      console.log(this.dataOptions);
      if (this.dataOptions.sortBy.length !== 0) {
        this.onboardtable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getCampaignManagementData();
      } else {
        this.onboardtable[SORTING_KEY] = "";
        this.getCampaignManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // campaign Modal
      toggleAddEditViewCampaignModal: "campaignManagement/toggleModal",
      toggleModalDelete: "campaignManagement/toggleModalDelete",
    }),
    getCampaignManagementData() {
      const self = this;
      this.onboardtable.loading = true;
      const successHandler = (res) => {
        self.onboardtable.items = res.data.school_list;
        self.onboardtable[PAGE_INFO][PAGE_NUMBER] =
          res.data[PAGE_INFO][PAGE_NUMBER];
        self.onboardtable[PAGE_INFO][TOTAL_PAGE] =
          res.data[PAGE_INFO][TOTAL_PAGE];
        self.onboardtable[PAGE_INFO][ITEMS_PER_PAGE] =
          res.data[PAGE_INFO][ITEMS_PER_PAGE];
        self.onboardtable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.onboardtable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.onboardtable[SEARCH];
      dataTableParams[ITEMS_PER_PAGE] =
        this.onboardtable[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        this.onboardtable[PAGE_INFO][PAGE_NUMBER];
      dataTableParams[SORTING_KEY] = this.onboardtable[SORTING_KEY];

      Axios.request_GET(
        API_ADMIN_GET_CAMPAIGN_ONBOARDLIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    newCampaign() {
      this.toggleAddEditViewCampaignModal({
        type: "add",
        show: true,
      });

      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "add" },
      });
    },
    editCampaign(item, action) {
      console.log("**********", item, action);
      this.toggleAddEditViewCampaignModal({
        show: true,
      });
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: item.id },
      });
    },
    viewCampaignHandler(campaign) {
      const { id, district, school, campaign_type } = campaign;
      if (campaign_type === "district") {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.districtPanel.children.dashboard.name,
          query: { [campaign_type]: district, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.schoolPanel.children.dashboard.name,
          query: { [campaign_type]: school, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    viewPublicDonationPageHandler(item) {
      console.log("in scooolll");
      const { sub_domain } = item;
      console.log("in sub_domain", item.sub_domain);

      Helper.openPublicDonationPage(sub_domain);
    },
    deleteCampaign(item) {
      const { id } = item;
      console.log(id);
      this.toggleModalDelete({ Delete: true, id: id });
    },
    getReport(id) {
      this.notificationModal.flag = true;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.notificationModal.flag = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.notificationModal.flag = false;
      };
      let formData = {};
      formData["campaign_id"] = id;
      Axios.request_GET_BLOB(
        API_DONATION_REPORT_GENERATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  position: absolute;
  right: 10px;
  letter-spacing: 0px;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.alignSelf-center {
  align-self: center;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 700;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
  min-width: 1500px;
}
.table-div {
  overflow-x: scroll;
}

.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-input.search-input {
    display: flex;
    width: 240px;
    margin-left: 10px;
  }
  .v-icon.notranslate.mobile-menu {
    position: absolute;
    top: 25px;
  }
  .table-div {
    overflow: scroll;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
