var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-row',{staticStyle:{"margin-left":"20px","margin-top":"25px"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"formFields",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-calendar-range","label":"Month","readonly":"","color":"#7253CF"},model:{value:(_vm.formatedStartDate),callback:function ($$v) {_vm.formatedStartDate=$$v},expression:"formatedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","type":"month"},on:{"input":function($event){_vm.menu = false},"change":function($event){return _vm.getEventSlotList()}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1)],1),(this.slotList.length==0)?_c('span',{staticStyle:{"margin-left":"40%"}},[_vm._v("No data available")]):_vm._e(),_vm._l((_vm.slotList),function(monthSlot){return _c('div',{key:monthSlot.date,staticStyle:{"border-bottom":"1px solid black","margin-left":"50px"}},[_c('br'),_c('span',{staticStyle:{"font-size":"19px","font-weight":"bold","width":"30%"}},[_vm._v(_vm._s(monthSlot.date_str)+" "),_c('v-icon',_vm._g(_vm._b({staticClass:"icons",staticStyle:{"margin-left":"2%"},attrs:{"dark":"","color":"red","size":"25"},on:{"click":function($event){return _vm.toggleDeletePlayerEventModal({
                Delete: true,
                id: monthSlot.slot.id,
              })}}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-delete ")]),(monthSlot.slot.details.length != 0)?_c('v-btn',{staticClass:"px-2",staticStyle:{"margin-left":"25px"},attrs:{"color":"#38227A","dark":""},on:{"click":function($event){return _vm.togglePlayerAvailabilityEventModal({
              show: true,
              type: 'edit',
              date: monthSlot.slot.date,
              id: monthSlot.slot.id,
            })}}},[_c('span',{staticStyle:{"margin-left":"5px","text-transform":"capitalize"}},[_vm._v("Edit")])]):_vm._e(),(monthSlot.slot.details.length == 0)?_c('v-btn',{staticClass:"px-2",staticStyle:{"margin-left":"25px"},attrs:{"color":"#38227A","dark":""},on:{"click":function($event){return _vm.togglePlayerAvailabilityEventModal({
              show: true,
              type: 'add',
              date: monthSlot.slot.date,
              id: monthSlot.slot.id,
            })}}},[_vm._v("Add")]):_vm._e(),_vm._l((monthSlot.slot.details),function(detail){return _c('div',{key:detail.id,staticStyle:{"font-size":"15px","font-weight":"400"}},[(detail.note !== null)?_c('div',{staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(detail.note)+" ")]):_vm._e()])}),_c('div',{staticStyle:{"font-size":"16px","font-weight":"bold","color":"#7253cf","margin-left":"-2px"}},[_vm._v(" (Available Slots-"+_vm._s(monthSlot.slot.empty_slots)+") ")])],2),_c('br'),_c('hr')])})],2),_c('player-availability-event'),_c('delete-player-event-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }